@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");

@font-face {
  font-family: "NotoSansKR";
  src: url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "NotoSansKR";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
  unicode-range: U+0020-007E;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

html {
  width: 100%;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  box-sizing: border-box;
  min-width: 1280px;
  min-height: 1080px;


  font-family: "NotoSansKR", sans-serif;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  height: 100vh;
  max-height: 1080px;
}
